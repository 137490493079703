<template>
  <Layout class="atbd-main-layout">
    <Content>
      <div v-if="isLoading" class="spin">
        <a-spin />
      </div>
      <div v-show="!isLoading">
        <router-view />
      </div>
    </Content>
  </Layout>
</template>
<script>

import {useStore} from 'vuex';
import {Layout} from 'ant-design-vue';
import {defineComponent, computed} from 'vue';
const {Content} = Layout;

export default defineComponent({
  name: 'RegistrationLayout',
  components: {
    Content,
    Layout
  },
  setup() {
    const {state} = useStore();
    const darkMode = computed(() => state.layout.darkMode);
    const isLoading = computed(() => state.layout.loading);

    return {
      darkMode, isLoading
    };
  }

});
</script>

<style lang="scss" scoped>

.ant-layout-content  {
  background-image: url("/src/static/img/auth/topShape.png");
  background-color: #cccccc;
}
</style>
